/*-----------------------------------------------
|   Navbar
-----------------------------------------------*/
.shadow-bottom{ box-shadow: 0 0.5rem 0.5rem -0.5rem rgba($black, 0.2) !important; }

/*-----------------------------------------------
|   Navbar vertical
-----------------------------------------------*/
$navbar-vertical-width: 12.625rem;
$vartical-navbar-padding: 1.5rem;
.navbar-top{
  top: 0;
  z-index: 1020;
}
.navbar-vertical{
  position: -ms-device-fixed !important;
  padding: 0;
  z-index: 1019;
  top: $top-nav-height;
  width: 100vw;

  .navbar-brand{ display: none; }
  .navbar-collapse{
    flex-direction: column;
    > * {
      width: 100%;
      overflow: hidden;
    }
  }
  .navbar-vertical-toggle{ display: none; }
  /*-----------------------------------------------
  |   Navbar nav
  -----------------------------------------------*/
  .navbar-nav{
    width: 100%;
    font-size: 0.875rem;
    font-weight: $font-weight-medium;
    .nav-link{
      color: $gray-700;
      white-space: nowrap;
      @include media-breakpoint-up('lg'){ padding: 0.35rem 0; }
      @include hover-focus{
        color: $gray-1000;
        &.dropdown-indicator:after{ border-color: $gray-1000; }
      }
    }
    .nav-link-icon{
      width: $vartical-navbar-padding;
      min-width: $vartical-navbar-padding;
      font-size: map_get($font-sizes, 0);
    }
    .nav{
      flex-flow: column nowrap;
      font-size: 0.8125rem;
      .nav-item{
        .nav-link{ padding: 0.2rem $vartical-navbar-padding; }
        &:last-child{ margin-bottom: 0.35rem; }
      }
      .nav{
        .nav-item{ .nav-link{ padding-left: $vartical-navbar-padding * 1.75; } }
        .nav{ .nav-item{ .nav-link{ padding-left: $vartical-navbar-padding * 2.75; } } }
      }
    }
  }
}

/*-----------------------------------------------
|   Break 
-----------------------------------------------*/

$breaks: ('xxl', 'xl', 'lg', 'md', 'sm', 'xs');
$navbar-collapsed-width: 3.4375rem;
$navbar-vertical-max-width: 12.625rem;

@for $i from 1 through length($breaks){
  $item: nth($breaks, $i);
  $j: '';
  @if length($breaks) != $i{
    $j: $i + 1;
    $down: nth($breaks, $j);
    @include media-breakpoint-down($down){
      @for $k from $j through length($breaks){
        $inneritem: nth($breaks, $k);
        @if length($breaks) != $k{
          .navbar-vertical.navbar-expand-#{$item}{
            @include media-breakpoint-only($inneritem){ 
              position: fixed;
              max-width: map_get($container-max-widths, $inneritem) !important; 
              width: 100%;
            }
            & + .content{
              padding-top: $top-nav-height;
              .navbar-top {
                position: fixed;
                top: 0;
                @include media-breakpoint-only($inneritem){
                  width: 100%;
                  max-width: map_get($container-max-widths, $inneritem) !important;
                }
              }
            }
          }
        }
      }
      .container-fluid{
        .navbar-vertical.navbar-expand-#{$item}{
          width: 100%;
          max-width: 100vw !important;
          & + .content .navbar-top{
            width: 100%;
            max-width: 100vw !important;
          }
        }
      }
    }
    .navbar-vertical.navbar-expand-#{$item}{
      @include media-breakpoint-up($item){
        position: fixed;
        width: 100%;
        top: 0;
        max-height: 100vh;
        display: block;
        max-width: $navbar-vertical-width;
        .navbar-vertical-toggle{ display: flex; }
        .navbar-brand{
          display: block;
          text-align: center;
        }
        & + .content{
          margin-left: $navbar-vertical-width + map_get($spacers, 5);
          .navbar-top{
            .navbar-brand, .navbar-toggler{ display: none;}
           }
        }
        .navbar-collapse{
          display: inline-block !important;
          width: $navbar-vertical-max-width !important;
          transition: $transition-base;
          padding-top: 0.5rem;
          height: calc(100vh - 69px);
          margin-top: -0.3125rem;
        }
        .nav-link-text{ 
          transition: $transition-base; 
        }
        .navbar-collapse{ 
          position: relative;
          // box-shadow: 0 0.5rem 0.5rem -0.5rem rgba($black, 0.2); 
          overflow-y: auto;
        }
        .settings .custom-control-label{ white-space: nowrap; }
        .safari &{ 
          .navbar-vertical .navbar-collapse{
            margin-top: 0.3125rem;
            padding-top: 0.5625rem;
          }
        }
      }

      @include media-breakpoint-down($down){
        
        & + .content .navbar-top .navbar-toggler{ display: flex; }
        
        margin-left: -($grid-gutter-width / 2);
        margin-right: -($grid-gutter-width / 2);
        .navbar-nav{
          padding-left: $grid-gutter-width / 2;
          padding-right: $grid-gutter-width / 2;
        }
        .navbar-collapse{
          &.show{
            max-height: calc(100vh - #{$top-nav-height});
            overflow-y: auto;
          }
        }
      }
    }
  }
}
@include media-breakpoint-only('xs'){
  .navbar-vertical{
    position: fixed;
    width: 100%;
    max-width: 100vw !important; 
  }
  .content{ padding-top: $top-nav-height; }
  // .navbar-top {
  //   position: fixed;
  //   width: 100%;
  //   max-width: 100vw !important;
  // }
}

// Navbar vertical collapse
.toggle-icon-wrapper{
  margin-left: -0.75rem;
  padding-left: 0.125rem;
  margin-right: 1.25rem;
}

@each $item, $value in $breaks {
  @include media-breakpoint-up($item){
    .navbar-vertical-collapsed{
      .navbar-vertical.navbar-expand-#{$item}{
        z-index: 1030;
        width: $navbar-collapsed-width;
        height: 100vh;
        background-color: transparent;
        .navbar-collapse{
          width: $navbar-collapsed-width !important;
          padding-right: map_get($spacers, 2);
          padding-left: 0.9375rem;
          margin-left: -0.9375rem;
          overflow: hidden;
        }
        .dropdown-indicator:after,
        .nav-link .badge,
        .nav-link-text,
        .settings{
          opacity: 0;
          transition: $transition-base;
        }
        .navbar-toggle-icon{
          padding-right: 0.3125rem;
          &:after{ width: 75%; }
          &:before{ width: 50%; }
        }
        
        + .content{ 
          margin-left: 3.125rem !important; 
          .navbar-top{ padding-left: 13.5rem; }
        }
      }
      .navbar-vertical-divider{ padding-right: 0.75rem !important; }
      &:not(.navbar-vertical-collapsed-hover){
        .navbar-vertical.navbar-expand-#{$item}{
          .navbar-collapse,
          .nav.collapse.show{ display: none; }
        }
      }
    }
    .navbar-vertical-collapsed-hover{
      .navbar-vertical.navbar-expand-#{$item}{
        width: $navbar-vertical-max-width;
        .navbar-collapse{
          width: $navbar-vertical-max-width !important;
          box-shadow: 10px 0 10px -9px rgba($black, 0.2);
          overflow: auto;
        }
        .dropdown-indicator:after,
        .nav-link .badge,
        .nav-link-text,
        .settings{ opacity: 1; }
        .navbar-vertical-divider{ padding-right: 0 !important; }
      }
    }
  }
}